<template>
  <div class="container-fluid">
    <div class="row d-flex justify-center align-center">
      <div
        class="col d-none d-md-flex col-md-12 col-lg-12 col-xl-9 flex-column"
      >
        <tgl-card-header>
          <h1>Create new user</h1>
        </tgl-card-header>
        <tgl-card-content>
          <div class="row d-flex justify-center align-center">
            <v-stepper v-model="creation" class="w-100" elevation="0">
              <v-stepper-header class="custom-stepper-header">
                <v-stepper-step step="1"> {{$t('user.userInformation')}}</v-stepper-step>

                <v-divider></v-divider>

                <v-stepper-step step="2"> {{$t('user.role')}} </v-stepper-step>

                <v-divider></v-divider>

                <v-stepper-step step="3"> {{$t('user.createWorker')}} </v-stepper-step>

                <v-divider></v-divider>

                <v-stepper-step step="4"> {{$t('user.passwordHeader')}} </v-stepper-step>
              </v-stepper-header>

              <v-stepper-items>
                <v-stepper-content step="1">
                  <step-1 @step="step" @user="setUser" :user="user"></step-1>
                </v-stepper-content>

                <v-stepper-content step="2">
                  <step-2 @step="step" @user="setUser" :user="user"></step-2>
                </v-stepper-content>

                <v-stepper-content step="3">
                  <step-3
                    @step="step"
                    @user="setUser"
                    @worker="setWorker"
                    :user="user"
                  ></step-3>
                </v-stepper-content>

                <v-stepper-content step="4">
                  <step-4 @step="step" @user="setUser" :user="user"></step-4>
                </v-stepper-content>
              </v-stepper-items>
            </v-stepper>
          </div>
        </tgl-card-content>
      </div>
    </div>
  </div>
</template>

<script>
import TglCardContent from "@/components/Cards/TglCardContent.vue";
import TglCardHeader from "@/components/Cards/TglCardHeader.vue";
import Step1 from "@/components/Users/Wizard/Step1.vue";
import Step2 from "@/components/Users/Wizard/Step2.vue";
import Step3 from "@/components/Users/Wizard/Step3.vue";
import Step4 from "@/components/Users/Wizard/Step4.vue";
export default {
  components: { TglCardHeader, TglCardContent, Step1, Step2, Step3, Step4 },
  data() {
    return {
      creation: 1,
      user: null,
      worker: null,
    };
  },
  methods: {
    step(step) {
      this.creation = step;
    },
    setUser(user) {
      this.user = user;
    },
    setWorker(worker) {
      this.worker = worker;
    },
  },
};
</script>

<style lang="sass">
.custom-stepper-header
  box-shadow: none !important
</style>