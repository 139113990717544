<template>
  <div class="content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12 pa-0 ma-0 tgl-page-header">
          <tgl-breadcrumbs :items="breadcrumbsCreate" />
        </div>
      </div>
      <div class="row">
        <div class="col-12 pa-0 ma-0">
          <user-form />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UserForm from "../../components/Users/UserForm";
import TglBreadcrumbs from "../../components/UI/TglBreadcrumbs";

export default {
  components: {
    UserForm,
    TglBreadcrumbs,
  },
  data: () => ({
    breadcrumbsCreate: [
      {
        label: "Usuarios",
        disabled: false,
        to: { name: "users" },
      },
      {
        label: "Crear usuario",
        disabled: true,
        to: { name: "usersCreate" },
      },
    ],
    user: null,
  }),
};
</script>