<template>
  <div>
    <v-card class="mb-12" height="270px" elevation="0">
      <v-form ref="form">
        <div class="row d-flex justify-center align-center">
          <v-checkbox v-model="createWorker" :label="$t('user.workerQuestion')">
          </v-checkbox>
        </div>
        <div v-if="!createWorker" style="height: 200px"></div>
        <div
          class="row d-flex justify-center align-center"
          style="height: 200px"
          v-if="createWorker"
        >
          <div class="col-lg-6 col-12">
            <div class="row d-flex align-center m-0 mb-5">
              <div class="col col-10">
                <v-text-field
                  :label="$t('user.rut')"
                  v-model="step3.rut"
                  :rules="[rules.required, rules.rut]"
                />
              </div>
              <v-tooltip bottom open-delay="500">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    color="primary"
                    size="20"
                    class="col col-2"
                    v-bind="attrs"
                    v-on="on"
                  >
                    mdi-information-outline</v-icon
                  >
                </template>
                <span>{{ $t("tooltips.userForm.rut") }}</span>
              </v-tooltip>
            </div>
          </div>
          <div class="col-lg-6 col-12">
            <div class="row d-flex align-center m-0 mb-5">
              <div class="col col-10">
                <v-text-field
                  :label="$t('user.alias')"
                  v-model="step3.alias"
                  :rules="[rules.required]"
                />
              </div>
              <v-tooltip bottom open-delay="500">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    color="primary"
                    size="20"
                    class="col col-2"
                    v-bind="attrs"
                    v-on="on"
                  >
                    mdi-information-outline</v-icon
                  >
                </template>
                <span>{{ $t("tooltips.userForm.alias") }}</span>
              </v-tooltip>
            </div>
          </div>
          <div class="col-lg-6 col-12">
            <div class="row d-flex align-center m-0 mb-5">
              <div class="col col-10">
                <v-select
                  item-value="id"
                  item-text="name"
                  :items="departments"
                  :label="$t('user.department')"
                  :rules="[rules.required]"
                  v-model="step3.departmentId"
                >
                </v-select>
              </div>
              <v-tooltip bottom open-delay="500">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    color="primary"
                    size="20"
                    class="col col-2"
                    v-bind="attrs"
                    v-on="on"
                  >
                    mdi-information-outline</v-icon
                  >
                </template>
                <span>{{ $t("tooltips.userForm.department") }}</span>
              </v-tooltip>
            </div>
          </div>
        </div>
        <div class="row d-flex justify-end align-center">
          <v-btn text :disabled="loading" @click="backStep"> Back </v-btn>
          <v-btn
            :loading="loading"
            color="primary"
            class="mr-6 ml-2"
            @click="handleSubmit"
          >
            Continue
          </v-btn>
        </div>
      </v-form>
    </v-card>
  </div>
</template>

<script>
import form from "@/mixins/form";

export default {
  name: "wizard-step-3",
  props: {
    user: {
      type: Object,
    },
    worker: {
      type: Object,
    },
  },
  mixins: [form],
  data() {
    return {
      step3: {
        rut: "",
        alias: "",
        departmentId: 1,
      },
      departments: [],
      createWorker: false,
      loading: false,
    };
  },
  methods: {
    handleSubmit() {
      setTimeout(() => {
        if (this.$refs.form.validate()) {
          this.wizardStep3();
        }
      }, 100);
    },
    wizardStep3() {
      if (this.createWorker) {
        this.loading = true;
        this.$store
          .dispatch("userV1/wizardStep3", {
            id: this.user.id,
            rut: this.step3.rut,
            alias: this.step3.alias,
            departmentId: this.step3.departmentId,
          })
          .then((data) => {
            if (data) {
              this.$emit("user", data);
              this.$emit("worker", this.step3);
              this.$emit("step", 4);
            }
          })
          .catch((err) => {
            console.error(err);
          })
          .finally(() => (this.loading = false));
      } else {
        this.$emit("step", 4);
      }
    },
    getDepartments() {
      this.$store.dispatch("department/getDepartments").then((data) => {
        this.departments = data;
        this.step3.departmentId = this.departments.id;
      });
    },
    backStep() {
      this.$emit("step", 2);
    },
    initForm() {
      this.step3.rut = this.worker.rut;
      this.step3.alias = this.worker.alias;
      this.step3.departmentId = this.worker.departmentId;
    },
  },
  mounted() {
    this.getDepartments();
    if (this.worker) {
      this.initForm();
      this.createWorker = true;
    }
  },
};
</script>

<style>
</style>