<template>
  <div>
    <v-card class="mb-12" elevation="0">
      <v-form ref="form" autocomplete="off">
        <div class="row justify-center align-center">
          <div class="col-lg-6 col-12">
            <div class="row d-flex align-center m-0 mb-5">
              <div class="col col-10">
                <v-text-field
                  :label="$t('user.name')"
                  v-model="step1.name"
                  :rules="[rules.required]"
                />
              </div>
              <v-tooltip bottom open-delay="500">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    color="primary"
                    size="20"
                    class="col col-2"
                    v-bind="attrs"
                    v-on="on"
                  >
                    mdi-information-outline</v-icon
                  >
                </template>
                <span>{{ $t("tooltips.userForm.name") }}</span>
              </v-tooltip>
            </div>
          </div>
          <div class="col-lg-6 col-12">
            <div class="row d-flex align-center m-0 mb-5">
              <div class="col col-10">
                <v-text-field
                  :label="$t('user.lastName')"
                  v-model="step1.lastName"
                  :rules="[rules.required]"
                />
              </div>
              <v-tooltip bottom open-delay="500">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    color="primary"
                    size="20"
                    class="col col-2"
                    v-bind="attrs"
                    v-on="on"
                  >
                    mdi-information-outline</v-icon
                  >
                </template>
                <span>{{ $t("tooltips.userForm.lastName") }}</span>
              </v-tooltip>
            </div>
          </div>
          <div class="col-lg-6 col-12">
            <div class="row d-flex align-center m-0 mb-5">
              <div class="col col-10">
                <v-text-field
                  :label="$t('user.phoneNumber')"
                  v-model="step1.phoneNumber"
                  :rules="[rules.required]"
                />
              </div>
              <v-tooltip bottom open-delay="500">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    color="primary"
                    size="20"
                    class="col col-2"
                    v-bind="attrs"
                    v-on="on"
                  >
                    mdi-information-outline</v-icon
                  >
                </template>
                <span>{{ $t("tooltips.userForm.contactNumber") }}</span>
              </v-tooltip>
            </div>
          </div>
          <div class="col-lg-6 col-12">
            <div class="row d-flex align-center m-0 mb-5">
              <div class="col col-10">
                <v-text-field
                  label="Email"
                  v-model="step1.email"
                  :rules="[rules.required, rules.email]"
                />
              </div>
              <v-tooltip bottom open-delay="500">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    color="primary"
                    size="20"
                    class="col col-2"
                    v-bind="attrs"
                    v-on="on"
                  >
                    mdi-information-outline</v-icon
                  >
                </template>
                <span>{{ $t("tooltips.userForm.email") }}</span>
              </v-tooltip>
            </div>
          </div>
          <div class="col-lg-6 col-12">
            <div class="row d-flex align-center m-0 mb-5">
              <div class="col col-10">
                <v-autocomplete
                  :items="timeZones"
                  :label="$t('user.timeZone')"
                  :rules="[rules.required]"
                  v-model="step1.tz"
                >
                </v-autocomplete>
              </div>
              <v-tooltip bottom open-delay="500">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    color="primary"
                    size="20"
                    class="col col-2"
                    v-bind="attrs"
                    v-on="on"
                  >
                    mdi-information-outline</v-icon
                  >
                </template>
                <span>{{ $t("tooltips.userForm.timeZone") }}</span>
              </v-tooltip>
            </div>
          </div>
        </div>
        <div class="row justify-end align-center">
          <v-btn text :disabled="loading" @click="cancel"> Cancel </v-btn>
          <v-btn
            color="primary"
            :loading="loading"
            class="mr-6 ml-2"
            @click="handleSubmit"
          >
            Continue
          </v-btn>
        </div>
      </v-form>
    </v-card>
  </div>
</template>

<script>
import form from "@/mixins/form";

export default {
  name: "wizard-step-1",
  props: {
    user: {
      type: Object,
    },
  },
  mixins: [form],
  data() {
    return {
      step1: {
        name: "",
        lastName: "",
        phoneNumber: "",
        email: "",
        tz: "",
      },
      loading: false,
      timeZones: [],
    };
  },
  methods: {
    handleSubmit() {
      setTimeout(() => {
        if (this.$refs.form.validate()) {
          if (this.user) {
            this.wizardStep1();
          } else {
            this.startWizard();
          }
        }
      }, 100);
    },
    startWizard() {
      this.loading = true;
      this.$store
        .dispatch("userV1/wizardStart", {
          name: this.step1.name,
          lastName: this.step1.lastName,
          phoneNumber: this.step1.phoneNumber,
          email: this.step1.email,
          tz: this.step1.tz,
        })
        .then((data) => {
          if (data) {
            this.$emit("step", 2);
            this.$emit("user", data);
          }
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => (this.loading = false));
    },
    wizardStep1() {
      this.loading = true;
      this.$store
        .dispatch("userV1/wizardStep1", {
          id: this.user.id,
          name: this.step1.name,
          lastName: this.step1.lastName,
          phoneNumber: this.step1.phoneNumber,
          email: this.step1.email,
          tz: this.step1.tz,
        })
        .then((data) => {
          if (data) {
            this.$emit("user", data);
            this.$emit("step", 2);
          }
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => (this.loading = false));
    },
    cancel() {
      this.$router.push({ name: "users" });
    },
    getOrganization() {
      this.$store
        .dispatch("organization/getOrganization")
        .then((data) => {
          this.step1.tz = data.tz;
        })
        .catch((err) => console.error(err));
    },
    getTimeZones() {
      this.$store
        .dispatch("timezone/getTimezones")
        .then((data) => {
          this.timeZones = data;
        })
        .catch((err) => console.error(err));
    },
    initForm() {
      this.step1.name = this.user.name;
      this.step1.lastName = this.user.lastName;
      this.step1.phoneNumber = this.user.phoneNumber;
      this.step1.email = this.user.email;
      this.step1.tz = this.user.tz;
    },
  },
  mounted() {
    if (this.user) {
      this.initForm();
    }
    this.getOrganization();
    this.getTimeZones();
  },
};
</script>

<style>
</style>