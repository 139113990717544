<template>
  <div>
    <v-card class="mb-12" height="270px" elevation="0">
      <v-form ref="form" autocomplete="off">
        <div
          class="row d-flex justify-center align-center"
          style="height: 270px"
        >
          <div class="col-lg-6 col-12">
            <div class="row d-flex align-center m-0 mb-5">
              <div class="col col-10">
                <v-checkbox v-model="step4.autogeneratePassword">
                  <template v-slot:label>
                    <span class="mt-3">{{
                      $t("user.autogeneratePassword")
                    }}</span>
                  </template>
                </v-checkbox>
              </div>
              <v-tooltip bottom open-delay="500">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    color="primary"
                    size="20"
                    class="col col-2"
                    v-bind="attrs"
                    v-on="on"
                  >
                    mdi-information-outline</v-icon
                  >
                </template>
                <span>{{ $t("tooltips.userForm.autogeneratePassword") }}</span>
              </v-tooltip>
            </div>
          </div>
          <div class="col-lg-6 col-12" v-if="!step4.autogeneratePassword">
            <div class="row d-flex align-center m-0 mb-5">
              <div class="col col-10">
                <v-text-field
                  v-model="step4.password"
                  :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="showPassword ? 'text' : 'password'"
                  :label="$t('user.password')"
                  counter
                  :rules="[rules.required, rules.password]"
                  @click:append="showPassword = !showPassword"
                ></v-text-field>
              </div>
              <v-tooltip bottom open-delay="500">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    color="primary"
                    size="20"
                    class="col col-2"
                    v-bind="attrs"
                    v-on="on"
                  >
                    mdi-information-outline</v-icon
                  >
                </template>
                <span>{{ $t("tooltips.userForm.password") }}</span>
              </v-tooltip>
            </div>
          </div>
        </div>
        <div class="row d-flex justify-end align-center">
          <v-btn text :disabled="loading" @click="backStep"> Back </v-btn>
          <v-btn
            color="primary"
            :loading="loading"
            class="mr-6 ml-2"
            @click="handleSubmit"
          >
            Finish
          </v-btn>
        </div>
      </v-form>
    </v-card>
  </div>
</template>

<script>
import form from "@/mixins/form";

export default {
  name: "wizard-step-4",
  props: {
    user: {
      type: Object,
    },
  },
  mixins: [form],
  data() {
    return {
      showPassword: false,
      step4: {
        autogeneratePassword: false,
        password: "",
      },
      loading: false,
    };
  },
  methods: {
    handleSubmit() {
      setTimeout(() => {
        if (this.$refs.form.validate()) {
          this.wizardStep4();
        }
      }, 100);
    },
    wizardStep4() {
      this.loading = true;
      this.$store
        .dispatch("userV1/wizardStep4", {
          id: this.user.id,
          autogeneratePassword: this.step4.autogeneratePassword,
          password: this.step4.password,
        })
        .then((data) => {
          if (data) {
            this.$toast.success("User created");
            this.$router.push({ name: "dashboard" });
          }
        })
        .catch((err) => {
          console.error(err);
          this.$toast.error("Something went wrong");
        })
        .finally(() => (this.loading = false));
    },

    backStep() {
      this.$emit("step", 3);
    },
    initForm() {
      this.step4.autogeneratePassword = this.user.autogeneratePassword;
      this.step4.password = this.user.password;
    },
  },
  mounted() {
    if (this.user) {
      this.initForm();
    }
  },
};
</script>

<style>
</style>