<template>
  <div>
    <v-card class="mb-12" height="270px" elevation="0">
      <v-form ref="form">
        <div
          class="row d-flex justify-center align-center"
          style="height: 270px"
        >
          <div class="col-lg-6 col-12">
            <div class="row d-flex align-center m-0 mb-5">
              <v-select
                item-value="id"
                item-text="name"
                :items="roles"
                :label="$t('user.role')"
                :rules="[rules.required]"
                v-model="step2.roleId"
              >
              </v-select>
              <v-tooltip bottom open-delay="500">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    color="primary"
                    size="20"
                    class="col col-2"
                    v-bind="attrs"
                    v-on="on"
                  >
                    mdi-information-outline</v-icon
                  >
                </template>
                <span>{{ $t("tooltips.userForm.role") }}</span>
              </v-tooltip>
            </div>
          </div>
        </div>
        <div class="row d-flex justify-end align-center">
          <v-btn text :disabled="loading" @click="backStep"> Back </v-btn>
          <v-btn
            color="primary"
            :loading="loading"
            class="mr-6 ml-2"
            @click="handleSubmit"
          >
            Continue
          </v-btn>
        </div>
      </v-form>
    </v-card>
  </div>
</template>

<script>
import form from "@/mixins/form";

export default {
  name: "wizard-step-2",
  props: {
    user: {
      type: Object,
    },
  },
  mixins: [form],
  data() {
    return {
      step2: {
        roleId: 1,
      },
      roles: [],
      loading: false,
    };
  },
  methods: {
    handleSubmit() {
      setTimeout(() => {
        if (this.$refs.form.validate()) {
          this.wizardStep2();
        }
      }, 100);
    },
    wizardStep2() {
      this.loading = true;
      this.$store
        .dispatch("userV1/wizardStep2", {
          id: this.user.id,
          roleId: this.step2.roleId,
        })
        .then((data) => {
          if (data) {
            this.$emit("user", data);
            this.$emit("step", 3);
          }
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => (this.loading = false));
    },
    getRoles() {
      this.$store
        .dispatch("role/getRoles")
        .then((data) => (this.roles = data))
        .catch((err) => console.error(err));
    },
    backStep() {
      this.$emit("step", 1);
    },
    initForm() {
      this.step2.roleId = this.user.rolerId;
    },
  },
  mounted() {
    if (this.user) {
      this.initForm();
    }
    this.getRoles();
  },
};
</script>

<style>
</style>